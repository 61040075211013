import { NavPage_SanityPage_slug, Slug } from '../__generated__/graphql';
import { PageContext } from './PageProps';
import { isNotNull } from './isNotNull';

export const getSlugsFromPage = <T extends { slug: NavPage_SanityPage_slug | undefined | null }>(
  page: T,
  prefix = ''
) => {
  const entries: [PageContext['locale'], Slug][] = page.slug
    ? (Object.entries(page.slug) as any)
    : [];

  const slugs = entries
    .map(([key, value]) => {
      if (!value) return null;
      return {
        locale: key,
        url: `${prefix}/${value?.current ?? ''}`,
      };
    })
    .filter(isNotNull);

  return slugs;
};
