import styled from 'styled-components';

/**
 *
 *  Your elements need classnames.
 *  zoom is the element with animation.
 *  zoom-activate will activate animation but not animate itself. It can be parent or sibling.
 *
 * */

export const ZoomImage = styled.div`
  .zoom-container {
    position: relative;
    overflow: hidden;
  }
  .zoom-img {
    transition: transform 0.25s, visibility 0.25s ease-in;
    @media screen and (min-width: 40em) {
      &:hover {
        transform: scale(1.1);
      }
    }
    @media screen and (max-width: 40em) {
      &:active {
        transform: scale(1.1);
      }
    }
  }

  .zoom-activate {
    @media screen and (min-width: 40em) {
      &:hover {
        ~ .zoom-img {
          transform: scale(1.1);
        }
        .zoom-img {
          transform: scale(1.1);
        }
      }
    }
    @media screen and (max-width: 40em) {
      &:active {
        ~ .zoom-img {
          transform: scale(1.1);
        }
        .zoom-img {
          transform: scale(1.1);
        }
      }
    }
  }
`;
